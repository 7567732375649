import { useFormik } from 'formik'
import * as Yup from 'yup'
import { StaticDataDTO } from '../../../../common/core/_models'
import { useState } from 'react'
import { useStaticDataList } from '../../static-data-list/core/StaticDataListProvider'

const createMerchantSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
})

const StaticDataForm = () => {
  const [submitMessages, setSubmitMessage] = useState({
    type: '',
    title: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);

  const {showFormStaticData, setFormStaticData, onAdd, onEdit} = useStaticDataList();

  const initialValues: StaticDataDTO = {
    id: showFormStaticData.data?.id || '',
    name: showFormStaticData.data?.name || '',
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: createMerchantSchema,
    onSubmit: async (values, { setFieldValue, resetForm }) => {
      // const venueDetail = dataVenue.find(val => val.id === values.venueId);
      // console.log('values >>> ', Object.assign(values, {
      //   location: venueDetail?.location, schedules: [{
      //     startAt: moment(),
      //     endAt: moment(values.endDate)
      //   }],
      //   links: values.link.split(',').map(v => v.trim()),
      // }));
      setSubmitMessage({
        type: '',
        title: '',
        message: '',
      })

      try {
        setLoading(true);

        let message = {
          type: 'success',
          title: 'Create Successfully!',
          message: 'Create Successfully!',
        };

        if (showFormStaticData.data) {
          await onEdit(values);
          message = {
            type: 'success',
            title: 'Update Successfully!',
            message: 'Update Successfully!',
          };
        } else {
          await onAdd(values);
        }
        setSubmitMessage(message);

        setTimeout(() => {
          setFormStaticData(false, undefined);
        }, 500);

        // const response = await onInviteMerchant({
        //   bankAccountNumber: values.bankAccountName,
        //   venueId: values.venueId,
        //   email: values.email,
        //   name: values.name,
        //   phone: values.phone,
        // })
        // setFieldValue('submitMessages', response)

        // if (response.type === 'success') {
        //   setTimeout(() => {
        //     resetForm()
        //     // onShowModalCreate(false)
        //   }, 500)
        // }
      } catch (error) {
        setSubmitMessage({
          type: 'error',
          title: 'Insert Error!',
          message: 'Create Static data error!',
        })
      } finally {
        setLoading(false);
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {submitMessages?.message && (
          <div
            className={`alert alert-${submitMessages?.type} d-flex align-items-center p-5 mb-10`}
          >
            <div className='d-flex flex-column'>
              <h5 className='mb-1'>{submitMessages?.title}</h5>
              <span>{submitMessages?.message}</span>
            </div>
          </div>
        )}

        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Name'
              {...formik.getFieldProps('name')}
              name='name'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='d-flex flex-row'>
          <button
            type='reset'
            onClick={() => formik.resetForm()}
            className='btn btn-light me-3 btn-lg'
            style={{ flex: 1 }}
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            style={{ flex: 1 }}
            disabled={!formik.isValid || loading}
          >
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
    </>
  )
}

export { StaticDataForm }
